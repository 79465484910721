import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import LargeBlogCard from "../components/Blog/LargeBlogCard";
import "../components/Blog/Blog.scss";
import Page from "../components/Page";

export default function Blog({ data }) {
  const { posts } = data.blog;
  const [sorting, setSorting] = useState("all");
  const [sorted, setSorted] = useState(posts);
  const [currentIndex, setCurrentIndex] = useState(1);
  const elementPerPage = 8;
  const ref = useRef(null);

  const scrollRef = () =>
    ref.current.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    if (posts && posts.length > 0) {
      if (sorting === "all") {
        setSorted(posts);
      } else if (sorting === "annat") {
        try {
          const sorted = posts.filter((post) => {
            return (
              post.frontmatter.tag !== "sparande" &&
              post.frontmatter.tag !== "produktivitet" &&
              post.frontmatter.tag !== "livsstil"
            );
          });
          setSorted(sorted);
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const sorted = posts.filter((post) => {
            return post.frontmatter.tag === sorting;
          });
          setSorted(sorted);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }, [sorting]);

  function updateSorting(sorting) {
    if (sorting) setSorting(sorting);
  }

  function loadMore() {
    setCurrentIndex(currentIndex + 1);
  }

  // Logic for displaying current posts
  const indexOfLastElement = currentIndex * elementPerPage;
  const currentElements = sorted.slice(0, indexOfLastElement);

  return (
    <>
      <Page
        title={"Blogg om personlig utveckling | Framgångspillret"}
        description="En blogg om sparande, produktivitet och personlig utveckling. Hitta inspiration och lär dig nya saker för att bli lite bättre än vad du var igår."
      >

        <div className="container m-auto mt-8 p-4 max-w-6xl">
          <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Bloggen</h1>
          <div className="blog-grid">
            {currentElements.map((post) => (
              <article key={post.id}>
                <LargeBlogCard
                  author={post.frontmatter.author}
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  blogtag={post.frontmatter.tag}
                  text={post.excerpt}
                  image={
                    post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                  }
                  link={"/blogg/" + post.frontmatter.pathname + "/"}
                />
              </article>
            ))}
          </div>
          {currentIndex * elementPerPage < sorted.length && (
            <div className="flex flex-row justify-center items-center mt-12 mb-4">
              <button className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-5 rounded-full" onClick={loadMore}>
                Ladda fler{" "}
              </button>
            </div>
          )}

        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex: "/(/blog/)/"} },
      sort: { fields: [frontmatter___date], order: DESC },
    ) {
      posts: nodes {
        frontmatter {
          date
          title
          pathname
          tag
          author
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        excerpt
        id
      }
    }
    image: file(relativePath: { eq: "productiveImage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
